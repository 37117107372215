.bannerMx{
  margin:1em 0 .5em;
  background-color:#FEC629; 
  width: 100%;  
  &:hover{
    background:$bg_rojoHov;
    a.link-rst .bannerMx__bigBoxHor__txt h4,
    a.link-rst .bannerMx__bigBoxHor__txt p{
      color:$txtW;

    }
  }
  a.link-rst{
    @include reset-a(#000);
    
  }

  &__bigBoxHor{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__img{
      padding: 0;
      flex-basis: 50%;
    }

    &__txt{
      padding:2em;
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      h4{
        transition:color 0.3s ease-out;
        font-weight: bold;
        text-align: center;
        color: $txtB;
        text-decoration: none;
        width:100%;
        
        span.salta{display:block;}
        
        @media screen and (max-width: $desktopS) {
          font-size:1.25rem; 
        }
        
        @media screen and (max-width: $tablet) {
          font-size:1rem; 
          text-align: center;
        }
        
      }
      //h4
      
      p.link{
        transition:all 0.3s ease-out;
        // text-decoration: underline;
        font-weight: bold;
        font-size: $txt*1.25;
        color:$txtRc;
        line-height: $lh*1.15;
        margin-bottom: 0;
      }
      //p.link

      @media screen and (max-width: $desktopB) {
        padding: 3% 1em;
      }

    }

    @media screen and (max-width: $desktopS) {
      flex-direction: column;
      align-items: initial;
    }
    
  }

}


.blqHome{
  margin:.5em 0 1em;
  display: flex;
  flex-direction: row;
  width: 100%;

  .blqHome__smallBoxAlt__img{
    overflow: hidden;
    img{
      transform:scale(1);
      transition: all 0.3s ease-out;
    }
  }
  
  a.link-rst{
    @include reset-a(#000);

    &:hover{
      .blqHome__bigBox__txt,
      .blqHome__smallBoxAlt__txt{
        background-color:$bg_rojoHov;
        color:$txtW;

        p.link{color:$txtW;}
      }
      .blqHome__smallBoxAlt__img,
      .blqHome__bigBox__img{
        img{
          transform:scale(1.2);
        }
      }

    }

  }

  &__bigBox{
    padding-left: 0;
    &__img{
      overflow: hidden;
      img{
        transition: transform 0.3s ease-out;
        transform: scale(1);
      }
      a{
        &:hover{
          img{transform:scale(1.2)}
        }
      }
    }
    &__txt{
      background-color:#FEC629; 
      transition: all 0.3s ease-out;
      padding:5% 1em;

      display: flex;
      flex-direction: column;
      align-items: center;


      h4{
        font-weight: bold;
      
        @media screen and (max-width: $desktopS) {
          font-size:1.25rem; 
        }

        @media screen and (max-width: $tablet) {
          font-size:1rem; 
          text-align: center;
        }

      }

      a{
        text-decoration: underline;
        font-weight: bold;
        color:$txtRc;
        line-height: $lh*1.15;
        
      }

      p.link{
        font-weight: bold;
        color:$txtRc;
        line-height: $lh*1.15;
        transition: all 0.3s ease-out;
        margin-bottom: .15em;

      }

      @media screen and (max-width: $desktopB) {
        padding: 5% 1em;
      }

    }
    
  }

  &__bigBoxAlt{
    padding: 0;
  }

  &__smallBoxAlt{
    padding-right: 0;

    &__txt{
      background-color:#FEC629; 
      padding:10% 1em;
      transition: all 0.3s ease-out;

      display: flex;
      flex-direction: column;
      align-items: center;

      h4{
        font-weight: bold;
        text-align: center;

        @media screen and (max-width: $tablet) {
          font-size:1rem; 
          text-align: center;
        }

      }

      a{
        text-decoration: underline;
        font-weight: bold;
        color:$txtRc;
        line-height: $lh*1.15
      }

      p.link{
        // text-decoration: underline;
        font-weight: bold;
        color:$txtRc;
        transition: all 0.3s ease-out;
        line-height: $lh*1.15;
        margin-bottom: .15em;
      }

      @media screen and (max-width: $desktopB) {
        padding: 4% 1em;
      }


    }

    @media screen and (max-width: $desktopS) {
      padding-right: 15px;
      padding-left: 15px;
    }

    
  }

  &__smallBox{
    overflow: hidden;
    padding-right:0;

    .overflow{
      overflow: hidden;
    }

    img{
      transition:transform 0.3s ease-out;
    }
    a{
      &:hover{
        img{
          transform:scale(1.1);
        }

      }
    }
    @media screen and (max-width: $desktopS) {
      padding-right: 15px;
      padding-left: 15px;
    }

  }

  .recetas img{

    @media screen and (max-width: $desktopS) {
      // width: 50%;
      // text-align: center;
    }

  }

  &__bigBox{
    padding-right: 0;
  }

  @media screen and (max-width: $desktopS) {
    flex-direction: column;
    flex-flow: wrap;
  }

  @media screen and (max-width: $tablet) {

    flex-direction: column;
    flex-flow: wrap;
  }

} //blq_home


//   ___ _    ___ ___  ___ ___   _  _  ___  __  __ ___ 
//  / __| |  |_ _|   \| __| _ \ | || |/ _ \|  \/  | __|
//  \__ \ |__ | || |) | _||   / | __ | (_) | |\/| | _| 
//  |___/____|___|___/|___|_|_\ |_||_|\___/|_|  |_|___|
//                                                     

// BUENA SUERTE ACTUALIZANDOLO! ^^

.slide.home{

  // z-index: -1;

  .container{
    position: relative;
    height: auto;
    min-height: 620px;
    
    .cont_banner{
      position: absolute;
      text-align: center;
      margin:0 auto;

      img{
        width: 450px;
        height: auto;
        display: block;

        @media screen and (max-width: $hmb) {
          width: 75%;
          margin: 0 auto;
        }

        @media screen and (max-width: 500px) {
          width: 65%;
          margin: 0 auto;
        }

        @include mobile{
          width: 50%;
        }


      }

      a.cta{
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        text-decoration: none;
        color: #000;
        background: #ffc62a;
        padding: 0.5em 1.25em;
        text-transform: uppercase;
        font-size:$txt*1.2;
        font-weight: bold;

        @include mobileB {
          font-size:$txt;
        }


        &:hover{
          background: $bg_rojoHov;
          color:#FFF;

        }
      }

    }

  }

  @media screen and (max-width: $hmb) {
    background-size: 100% auto;
  }


  .slide01{
    background:url(../assets/img/home/slide/bg_slide_01.jpg);
    height: 620px;

    .container{

      @media screen and (max-width: $hmb) {
        min-height: 400px;
      }

    }

    .cont_banner{
      right: 15%;
      top: 40%;

      @include desktopS {
        right: 0;
      }

      @include tablet {
        right: -3em;
      }

      @media screen and (max-width: $hmb) {
        top: 20%;
        right: -3em;
      }

      @include mobileB {
        top:17%;
        right: -2em;
      }

      @include mobile {
        top:18%;
        right: -5em;
      }

      @include mobileS {
        top:13%;
        right:-6em;
      }

    }

    @media screen and (max-width: 1280px) {
      background-position: -250px center;
    }

    @include desktopB {
      background-position: -350px center;
    }

    @media screen and (max-width: 1024px) {
      background-position: -400px center;
    }

    @include desktopS {
      background-position: -450px center;
    }

    @include tablet {
      background-position: -525px center;
    }

    @media screen and (max-width: $hmb) {
      height: 400px;
      background-size: 200% auto;
      background-repeat: no-repeat;
      background-position: center top;
    }

    @include mobileB {
      height: 375px;
    }

    @media screen and (max-width: 500px) {
      height: 325px;
      background:url(../assets/img/home/slide/bg_slide_01_mb.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }

    @include mobile {
      height: 300px;
    }

    @include mobileS {
      height: 235px;
    }

  }

  .slide02{
    background:url(../assets/img/home/slide/bg_slide_02.jpg);
    height: 620px;

    .cont_banner{
      right: 10%;
      top: 25%;

      @include desktopS {
        right: 0;
      }

      @include tablet {
        right: -4em;
      }

      @media screen and (max-width: $hmb) {
        top: 15%;
        right: -5em;
      }

      @include mobileB {
        top:15%;
      }

      @media screen and (max-width: 500px) {
        top:10%;
        right: -3em;
      }

      @include mobile {
        top:10%;
        right: -5em;
      }

      @include mobileS {
        top:7%;
        right:-6em;
      }

    }

    @media screen and (max-width: 1280px) {
      background-position: -275px center;
    }

    @include desktopB {
      background-position: -380px center;
    }

    @media screen and (max-width: 1024px) {
      background-position: -450px center;
    }

    @include desktopS {
      background-position: -500px center;
    }

    @include tablet {
      background-position: -615px center;
    }

    @media screen and (max-width: $hmb) {
      height: 400px;
      background-size: 200% auto;
      background-repeat: no-repeat;
      background-position: center top;
    }

    @include mobileB {
      height: 375px;
    }

    @media screen and (max-width: 500px) {
      height: 325px;
      background:url(../assets/img/home/slide/bg_slide_02_mb.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }

    @include mobile {
      height: 300px;
    }

    @include mobileS {
      height: 235px;
    }

  }

  .slide03{
    background:url(../assets/img/home/slide/bg_slide_03.jpg);
    height: 620px;

    .cont_banner{
      right: 5%;
      top: 40%;

      @include desktopS {
        right: 0;
      }

      @include tablet {
        right: -4em;
      }

      @media screen and (max-width: $hmb) {
        top: 20%;
      }

      @include mobileB {
        top:20%;
      }

      @media screen and (max-width: 500px) {
        top:15%;
        right: -3em;
      }

      @include mobile {
        right: -5em;
      }

      @include mobileS {
        top:13%;
        right:-6em;
      }

    }

    @media screen and (max-width: 1280px) {
      background-position: -285px center;
    }

    @include desktopB {
      background-position: -400px center;
    }

    @media screen and (max-width: 1024px) {
      background-position: -480px center;
    }

    @include desktopS {
      background-position: -655px center;
    }

    @media screen and (max-width: $hmb) {
      height: 400px;
      background-size: 200% auto;
      background-repeat: no-repeat;
      background-position: center top;
    }

    @include mobileB {
      height: 375px;
    }

    @media screen and (max-width: 500px) {
      height: 325px;
    }

    @media screen and (max-width: 500px) {
      height: 325px;
      background:url(../assets/img/home/slide/bg_slide_03_mb.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }

    @include mobile {
      height: 300px;
    }

    @include mobileS {
      height: 235px;
    }

    .banner{
      min-height: 150px;

      @media screen and (max-width: 500px) {
        min-height: 110px;
      }

      @include mobileS {
        min-height: 85px;
      }

    }

  }

  .slide04{
    background:url(../assets/img/home/slide/bg_slide_04.jpg);
    height: 620px;

    .cont_banner{
      right: 10%;
      top: 35%;

      @include desktopS {
        right: 0;
      }

      @include tablet {
        right: -4em;
      }

      @media screen and (max-width: $hmb) {
        top: 20%;
      }

      @include mobileB {
        top:15%;
      }

      @media screen and (max-width: 500px) {
        top:20%;
        right: -3em;
      }

      @include mobile {
        top:18%;
        right: -5em;
      }

      @include mobileS {
        top:13%;
        right:-6em;
      }

    }

    @media screen and (max-width: 1280px) {
      background-position: -285px center;
    }

    @include desktopB {
      background-position: -400px center;
    }

    @media screen and (max-width: 1024px) {
      background-position: -480px center;
    }
    
    @include desktopS {
      background-position: -475px center;
    }

    @include tablet {
      background-position: -550px center;
    }

    @media screen and (max-width: $hmb) {
      height: 400px;
      background-size: 200% auto;
      background-repeat: no-repeat;
      background-position: center top;
    }

    @include mobileB {
      height: 375px;
    }

    @media screen and (max-width: 500px) {
      height: 325px;
    }

    @media screen and (max-width: 500px) {
      height: 325px;
      background:url(../assets/img/home/slide/bg_slide_04_mb.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }

    @include mobile {
      height: 300px;
    }

    @include mobileS {
      height: 235px;
    }

    .banner{
      min-height: 200px;

      @media screen and (max-width: $hmb) {
        min-height: 160px;
      }


      @media screen and (max-width: 500px) {
        min-height: 140px;
      }

      @include mobile {
        min-height: 110px;
      }

    }

  }

  .slide05{
    background:url(../assets/img/home/slide/bg_slide_05.jpg);
    height: 620px;

    .cont_banner{
      right: 10%;
      top: 35%;

      @include desktopS {
        right: 0;
      }
      @include tablet {
        right: -4em;
      }

      @media screen and (max-width: $hmb) {
        top: 20%;
      }

      @include mobileB {
        right: -3em;
        top: 20%;
      }

      @include mobile {
        top:18%;
        right: -5em;
      }

      @include mobileS {
        top:13%;
        right:-6em;
      }

    }

    @media screen and (max-width: 1280px) {
      background-position: -285px center;
    }

    @include desktopB {
      background-position: -400px center;
    }

    @include tablet {
      background-position: -450px center;
    }

    @media screen and (max-width: $hmb) {
      height: 400px;
      background-size: 200% auto;
      background-repeat: no-repeat;
      background-position: center top;
    }

    @include mobileB {
      height: 375px;
    }

    @media screen and (max-width: 500px) {
      height: 325px;
      background:url(../assets/img/home/slide/bg_slide_05_mb.jpg);
      background-size: cover;
      background-repeat: no-repeat;
    }

    @include mobile {
      height: 300px;
    }

    @include mobileS {
      height: 235px;
    }

  }

  .slide06{
    background:url(../assets/img/home/slide/bg_slide_polanco.jpg);
    background-position: center center;
    height: 620px;

    .container{

      @media screen and (max-width: $hmb) {
        min-height:400px;
      }

      @media screen and (max-width: 500px) {
        min-height:325px;
      }

      @media screen and (max-width: $mobile) {
        min-height:300px;
      }

      @media screen and (max-width: $mobileS) {
        min-height:235px;
      }

    }

    .cont_banner{
      right: 45%;
      top: 85%;

      @media screen and (max-width: $desktopS) {
        right: 40%;
        top: 75%;
      }

      @media screen and (max-width: 850px) {
        top:65%;
      }

      @media screen and (max-width: $tablet) {
        right: 35%;
        top: 85%;
      }

      @media screen and (max-width: $hmb) {
        top: 55%;
      }

      @media screen and (max-width: 600px) {
        top: 80%;
      }

      @media screen and (max-width: $mobileB) {
        // top: 65%;
        right: 40%;
      }

      @media screen and (max-width: $mobile) {
        top: 80%;
        right: 10%;
      }


    }

    @media screen and (max-width: $desktopS) {
      height: auto;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: auto 90%;
    }

    @media screen and (max-width: 850px) {
      height: auto;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: auto 80%;
    }

    @media screen and (max-width: $tablet) {
      height: auto;
      background:url(../assets/img/home/slide/bg_slide_polanco_mb.jpg);
      background-size: 130% auto;
      background-repeat: no-repeat;
      background-position: center top;
    }

    @media screen and (max-width: $hmb) {
      height: 400px;
      background-size: 110% auto;
      background-repeat: no-repeat;
      background-position: center bottom;
    }

    @media screen and (max-width: 500px) {
      height: 325px;
      background-size: 105% auto;
      background-repeat: no-repeat;
      background-position: center bottom;
    }

    @media screen and (max-width: $mobile) {
      height: 300px;
      background-size: 130% auto;
      background-repeat: no-repeat;
      background-position: center bottom;
    }

    @media screen and (max-width: $mobileS) {
      height: 235px;
      background-size: 109% auto;
      background-repeat: no-repeat;
      background-position: center top;
    }

  }



}