.beauty{
    .header{
        background-image:url("../assets/img/beauty/banner.jpg");
    }
}

.beauty__tips{
    padding-top:4em;
    padding-bottom:4em;
    background:url("../assets/img/beauty/fondo_belleza.jpg") no-repeat top left /cover;
    @include tablet{
        padding-top:0;
        .container{
            background:rgba($white,0.8);
            padding-top:4em;

        }
        // padding:15px;
    }
    &__tip{
        display:flex;
        flex-direction: row-reverse;
        align-items: center;
        flex-wrap:wrap;
        
        
        &:nth-child(even){
            flex-direction: row;
            .beauty__tips__tip__text{
                padding-left:0;
                padding-right:2em;
            }
        }
        &__text{
            width:66%;
            padding-left:2em;
            @include hasta (767.5px){
                width:100%;
            }
            h2{
                color:$yellow;
                font-weight: 700;
                margin-bottom:1em;
            }
            
        }
        &__image{
            width:33%;
            @include hasta (767.5px){
                width:100%;
                margin-bottom:3em;
        }
            img{
                width:100%;
                max-width:100%;
            }
        }
    }
}
