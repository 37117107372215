.footer{
    background-color: $bg_rojo;
    display: flex;
    align-items: flex-end;
    padding:30% 0 0;
    background-image:url('../assets/img/common/img_footer.jpg');
    background: {
      attachment: fixed;
      size:cover;
      repeat: no-repeat;
      position: center center;
    }

  & .row{
    width: 100%;
    background-color: $bg_rojo;
    margin: 0;
  }

  &__contLinks{
    align-items: center;
    border: 0;
    display:flex;
    background-color:$bg_rojo;

    @media screen and (max-width: $mobileB) {

      flex-direction: column-reverse;
      justify-content: center;
      
    }
  }

    &__linksContact{
      padding: 3em 0;
      display: flex;
      border:0;
      justify-content: space-around;
      align-items: center;
      color:$txtW;

      p{
        margin:0;

        @media screen and (max-width: $desktopB) {
          font-size: $txt*.85;
          line-height: $lh*1.15;
        }

      }

      a{
        @include reset-a(#FFFFFF);
        font-size: $txt;

        @media screen and (max-width: 1280px) {
          font-size: $txt*.85;
        }

        @media screen and (max-width: $desktopB) {
          font-size: $txt;
          line-height: $lh*1.15;
        }


      }

      @media screen and (max-width: $desktopB) {

        padding: .5em 0;
        flex-direction: column;

      }

      @media screen and (max-width: $desktopS) {
        flex-direction: column;
        justify-content: center;
        padding:1em 0;
        font-size: $txt*.85;

        a{
          margin:0 0 .25em 0;
          font-size: $txt*.85;

          @media screen and (max-width: $mobile) {
            font-size: $txt*.80;
            margin:0;
          }

        }

        p{
          margin:0 0 .25em 0;

          @media screen and (max-width: $mobileB) {
          }

          @media screen and (max-width: $mobile) {
            margin:0;
          }

        }

      }

      @media screen and (max-width: $mobileB) {
        padding: 0;
        text-align: center;
      }

      @media screen and (max-width: $mobile) {
        font-size: $txt*.80;
      }

    }

    &__linksSocial{
      display: flex;
      justify-content: flex-end;

      i.fa{
        margin:0 .5em;
        color:$txtW;
        font-size:$txt*2.50;

        @media screen and (max-width: $desktopB) {
          margin:0 .25em;
        }

        @media screen and (max-width: $desktopS) {
          font-size:$txt*2;
        }

        @media screen and (max-width: $mobile) {
          font-size:$txt*1.70;
        }


      }

      @media screen and (max-width: $mobileB) {
        justify-content: center;
        margin:.5em 0 .25em;
      }

    }

    &__newsLink{
      background-color:$bg_rojo2;
      padding: 2.70em 1em;
      text-align: center;
      font-size: $txt*1.10;
      display: flex;
      justify-content: center;
      align-items: center;

      a{
        text-decoration: none;
      }

      &:hover{
        background-color: $bg_rojoHov; 
        cursor:pointer;
      }

      @media screen and (max-width: $desktopS) {
        font-size: $txt;
      }

      @media screen and (max-width: $mobile) {
        font-size: $txt*.90;
        padding: 2em 1em;
      }

    }

}


// BANDA SUSCRIPCION NEWS
.stripNews{
  background-color: $bg_rojo;
  padding: 2em 0;

  &__form{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    span.form-error{
      color: $txtW;
      font-size: $txt*.85;
      text-align: center;
      display: block;
    }

    h3{
      color:$txtW;
    }

    h4{
      color:$txtW;
      font-size:$txt;

      @media screen and (max-width: $mobile) {
        margin-bottom: 1em;
      }

    }


    @media screen and (max-width: $desktopS) {
      h3{
        text-align: center;
      }

      h4{
        font-size:$txt*0.9;
        text-align: center;
      }
    }

    @media screen and (max-width: $mobile) {
      flex-direction: column;
    }


  } //__form

  &__contForm{

    display:flex;
    flex-direction: row;

    @media screen and (max-width: $desktopS) {
      flex-direction: column;
    }


  }

  &__formInputs{
    display: flex;
    flex-flow:column;
    justify-content: center;

    input{
      padding:.5em;
      background-color:$bg_rojo2;
      border: 0;
      &:focus{
        color: #FFF;
      }
      @include inputPlace(#FFFFFF, $txtRo);

      &#name{
        margin:0 0 .5em 0;
      }
      &#email{
        margin:0 0 .5em 0;
      }

    } 

    @media screen and (max-width: $desktopS) {
        margin:0 0 .5em 0;
    }

  } //__formInputs

  &__formSubmit{
    display: flex;
    justify-content: center;
    align-items: center;

    .btn{
      border: 0;
      background-color:$bg_rojo2;
      border-radius:0;
      padding:.5em 2em;
      color:$txtW;
      text-transform:uppercase;
      
      &:hover, &:active{
        background-color:$bg_rojoHov;
      }

    }

  } //__formSubmit

  @media screen and (max-width: $mobile) {
    padding: 1em 0;
  }


}


.cookiealert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 !important;
    z-index: 999;
    opacity: 0;
    border-radius: 0;
    background: rgba(33,35,39,.85);
    -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
    color: #ecf0f1;
    padding: 1em;
    text-align: center;

    border-top: 2px solid $bg_rojo2;

  &.show {
    opacity: 1;
    -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transition-delay: 1000ms;
         -o-transition-delay: 1000ms;
            transition-delay: 1000ms;
  }

  a {
    text-decoration: underline
  }

  .cookietext{
    max-width: 1290px;
    margin: 0 auto;

    font-size: $txt;
    line-height: $lh*1.2;
  }

  .cont_cookie_btn{
    margin:.25em auto 1em;
  }

  .acceptcookies {
    vertical-align: baseline;
    font-size: $txt;

    cursor: pointer;

    background-color:  $bg_rojo2 ;
    border: 1px solid  #FFF ;
    color: #FFF;
    padding:.35em .85em;

    &:hover{
      background-color: $bg_rojoHov;
    }

  }

}


@media screen and (max-width:1024px){
  .cookiealert{
    width: auto;
    line-height: normal;
  }
}
