.cab_contacto{

  background:url(../assets/img/polanco/bg_polanco.jpg);
  background-repeat: no-repeat;
  background-position:center top;
  background-size: cover;
  padding:2% 1em;

  border-bottom:2px solid #FFC72A;

  .tit_contact{
    
    h1{
      color: #FFC72A;
      font-size: 2.35rem;
      line-height: 40px;
      font-weight: bold;
      margin:.5em 1em;

      span.salta{
        display:block;
      }

      @media screen and (max-width: $mobileB) {
        text-align: center;
      }

    }
  }

  .dir_contact{
    display:flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin:3em 2em;

    .postal_contact{

      display: flex;
      flex-direction: row;
      align-content: flex-start;
      justify-content: center;

      .txt{

        padding:0 1em;

        h6{
          font-weight: bold;
          color: $txtB;
        }

        p{
          font-size:$txt;
          line-height: $lh;
        }

        span.salta{
          display: block;
        }

      }

      .ico{

        i.fa{
          padding:.25em .45em;
          background: $bg_rojo2;
          color: $txtW;
          font-size:$txt*1.5
        }
      }

    }

    .mail_contact{
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      justify-content: center;

      .txt{
        padding:.5em 1em;

        a{
          color: $txtB;
          font-weight: bold;
          text-decoration: underline;
        }
      }

      .ico{

        i.fa{
          padding:.45em;
          background: $bg_rojo2;
          color: $txtW;
          font-size:$txt*1.25
        }
      }

    }

  }

}

// FORMULARIO

.contact{

  background:url(../assets/img/polanco/bg_polanco.jpg);
  background-repeat: no-repeat;
  background-position:center bottom;
  background-size: cover;

  .cont_form{
    padding:5em 1em;

    .inputs{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      line-height: normal;
      margin:0 0 .5em 0;

      &__input{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin:.5em;

        input{
          padding:.75em 1em;
          width: 32%;
          border: 0;
          background: #FEF1CF;
          outline: none;
          line-height: normal;
          margin-bottom:.5em;
        }
        
      }

      .form-error{
        color: $txtRo;
        font-weight: bold;
        font-size: $txt*.85;

        display: block;
        text-align: center;
      }

      &__text{
        width: 100%;
          margin:.5em;

        textarea{
          padding:.75em 1em;
          width: 100%;
          border: 0;
          background: #FEF1CF;
          height: 10em;
        }
      }
    }

    .bajo_form__legal .form-error{
      color: $txtRo;
      font-weight: bold;
      font-size: $txt*.75;

      margin-left: 1em;
    }

  }

  .bajo_form{

    &__send{
      margin-top: 1em;

      input{
        border: 0;
        background: $bg_rojo2;
        padding:.75em 3em;
        color:$txtW;
        font-weight: bold;
        cursor: pointer;

        &:hover{
          background:$bg_rojo;
        }
      }

    }
  }
}

//  +-+-+-+-+-+-+ +-+-+-+-+-+-+-+-+-+ +-+-+-+-+-+ +-+-+ +-+-+-+-+-+-+-+
//  |P|A|G|I|N|A| |P|R|O|M|O|C|I|O|N| |S|A|B|O|R| |E|S| |P|O|L|A|N|C|O|
//  +-+-+-+-+-+-+ +-+-+-+-+-+-+-+-+-+ +-+-+-+-+-+ +-+-+ +-+-+-+-+-+-+-+
.cab_polanco{

  background:url(../assets/img/polanco/bg_polanco.jpg);
  background-repeat: no-repeat;
  background-position:center -160px;
  background-size: cover;
  padding:2% 0;

  @media screen and (max-width: $desktopB) {
    background-position: center -30px;
  }
  
  @media screen and (max-width: $tablet) {
    padding: 5% 0;
    background-position: center top;
  }

  @media screen and (max-width: $hmb) {
    background: none;
    padding: 1em 0;
  }

  .tit_contact{
    width: 100%;
    
    h1{
      color: #FFC72A;
      font-size: 3rem;
      line-height: 50px;
      font-weight: bold;
      margin:.5em 1em;

      span.salta{
        display:block;

        @media screen and (max-width: $tablet) {
          display: inline;
        }
      }

      @media screen and (max-width: $desktopB) {
        font-size: 2.75rem;
        line-height: 50px;
        text-align: center;
      }

      @media screen and (max-width: $desktopS) {
        font-size: 2.25rem;
        line-height: 40px;
      }

      @media screen and (max-width: $tablet) {
        font-size: 1.95rem;
        margin: .5em 0;
      }

      @media screen and (max-width: $mobileB) {
        font-size: 1.65rem;
        margin: .5em;
        line-height: normal;
      }

      @media screen and (max-width: $mobile) {
        font-size: 2rem;
        margin: .5em;
        line-height: normal;
      }

      @media screen and (max-width: $mobileS) {
        font-size: 1.65rem;
        margin: .5em;
        line-height: normal;
      }
    }
  }

  .dir_polanco{
    display:flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin:3em 0 0;
    align-items: center;

    @media screen and (max-width: $desktopB) {
      margin: 3em 3em 0 0;
    }

    @media screen and (max-width: $desktopS) {
      margin: 1em 0;
    }

    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }


    .info_polanco{

      .txt{
        padding:0 1em;

        h6{
          font-weight: bold;
          color: $txtB;
          line-height: normal;

          @media screen and (max-width: $mobileB) {
            text-align: center;
          }

          @media screen and (max-width: $mobile) {
            font-size: $txt*1.35;
          }

          @media screen and (max-width: $mobileS) {
            font-size: $txt*0.95;
          }
        }

        ol{
          padding-left: 15px;

          li{
            font-size:$txt;
            line-height: $lh*1.4;

            @media screen and (max-width: $mobileS) {
              font-size: $txt*0.90;
              line-height: $lh;
            }
          }
        }

        p{
          font-size:$txt*0.9;
          line-height: $lh*0.4;
        }

        span.salta{
          display: block;
        }

        @media screen and (max-width: $tablet) {
          padding: 0 2em;
        }
      }
    }

    .logo_polanco{
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      justify-content: center;

      img{
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: $hmb) {
        margin: .5em 0;
      }
    }
  }
}

//  +-+-+-+-+-+-+-+-+-+-+ +-+-+-+-+-+-+-+
//  |F|O|R|M|U|L|A|R|I|O| |P|O|L|A|N|C|O|
//  +-+-+-+-+-+-+-+-+-+-+ +-+-+-+-+-+-+-+

.contact_polanco{

  background:url(../assets/img/contacto/bg_contacto.jpg);
  background-repeat: no-repeat;
  background-position:center -760px;
  background-size: cover;
  margin: 2em 0;

  @media screen and (max-width: $desktopB) {
    background-position: center -360px;
    margin: 0 0 2em 0;
  }

  @media screen and (max-width: $tablet) {
    margin: 0;
    background-position: center -225px;
  }

  @media screen and (max-width: $tablet) {
    background: none;
  }

  @media screen and (max-width: $hmb) {
    background:url(../assets/img/polanco/bg_polanco_mob.jpg);
    background-position: right bottom;
  }

  @media screen and (max-width: $mobileB) {
    background: none;
  }

  .cont_form{
    padding:1em 0 15%;

    .inputs{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      line-height: normal;
      margin:0 0 1em 0;

      &__input{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin:0 .25em;

        .form-group{
          margin-bottom: 0;
          width: 33%;

          @media screen and (max-width: $tablet) {
            width: 100%;
          }
        }

        input{
          padding:.75em 1em;
          width: 32%;
          border: 0;
          background: #FEF1CF;
          outline: none;
          line-height: normal;
          margin-bottom:.5em;
        }

        @media screen and (max-width: $tablet) {
          flex-direction: column;
        }

        @media screen and (max-width: $mobileB) {
          width: 85%;
        }

        .foreverAlone{
          margin:0;
          width: 100%;

          textarea{
            height: auto;
            max-height: 43px;
            outline: none;
            resize: none;
             overflow: auto;
          }
        }

      }

      .form-error{
        color: $txtRo;
        font-weight: bold;
        font-size: $txt*.85;

        display: block;
        text-align: center;
        margin-bottom: .5em;
      }

      &__text{
        width: 100%;
          margin:.5em;

        textarea{
          padding:.75em 1em;
          width: 100%;
          border: 0;
          background: #FEF1CF;
          height: 10em;
        }
       
      }
    }

    @media screen and (max-width: $tablet) {
      padding:0 0 15%;
    }
  }

  .bajo_form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .bajo_form__checks{

      display:flex;
      flex-direction: row;

      &__legal{
        margin: 0 1em 0 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        span{
          font-size: $txt*.80;
        }

        @media screen and (max-width: $tablet) {

          align-items: flex-start;
          justify-content: flex-end;
          margin: 0 0 .25em 0;
        }

        @media screen and (max-width: $hmb) {
          margin: .30em 0;
        }

        @media screen and (max-width: $mobileB) {
          // flex-direction: column;
        }
      }

      //  +-+-+-+-+-+-+ +-+-+-+-+-+-+-+-+
      //  |C|U|S|T|O|M| |C|H|E|C|K|B|O|X|
      //  +-+-+-+-+-+-+ +-+-+-+-+-+-+-+-+
      .custom__check{
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid $bg_rojo2;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
        padding: 9px;
        border-radius: 0;
        display: inline-block;
        position: relative;
        margin-right: .5em;
        outline: none;

        &:active, &:checked:active{
          
          box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);

        }

        &:checked{
          background-color: $bg_rojo2;
          border: 2px solid $bg_rojo2;
          box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
          color:$bg_rojo2;
        }

      }

      @media screen and (max-width: $tablet) {
        flex-direction: column;
        align-items: flex-start;
      }
     
      @media screen and (max-width: $hmb) {
        width: 85%;
        margin: 0 auto;
      }
    }

    .bajo_form__checks__legal .form-error{
      color: $txtRo;
      font-weight: bold;
      font-size: $txt*.65;
      margin-left:1em;

      @media screen and (max-width: $tablet) {
        margin-top: .5em;
      }

      @media screen and (max-width: $mobileB) {
        margin-top: 0;
      }

      @media screen and (max-width: $mobileS) {
        margin-left: 12%;
      }
    }

    .bajo_form__checks__legal.has-error{
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  //  +-+-+-+-+-+ +-+-+-+-+-+-+
  //  |B|O|T|O|N| |E|N|V|I|A|R|
  //  +-+-+-+-+-+ +-+-+-+-+-+-+
  .bajo_form__send{
    margin-top: 1em;
    align-self: flex-end;

    input{
      border: 0;
      background: $bg_rojo2;
      padding:.75em 3em;
      color:$txtW;
      font-weight: bold;
      outline: none;
      cursor: pointer;

      &:hover{
        background:$bg_rojo;
      }
    }

    @media screen and (max-width: $tablet) {
      margin-top: 2em;
      align-self: center;
    }
  }
}

// LEGALES POLANCO

.blq_legal.polanco{

  .sub-tit{display: block;}
  .strong{
    color: $txtRo;
    font-weight: bold;
  }
  .link_legal{
    color: $txtRo;
    text-decoration: none;
  }

}