.blq_404{

  padding:3em 2em;
  border-bottom:2px solid #FFC72A;

  .err_404{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    img{
      width: 60%;
      height: auto;
      padding: 1em;
    }

    h4{
      color: $yellow;
      font-weight: bold;
    }

    .btn{
      border: 0;
      background-color: #BD2E1E;
      border-radius: 0;
      padding: .5em 2em;
      color: #fff;
      text-transform: uppercase;
      margin-top: 1.5em;

      &:hover{
        background-color: #9D2C22;
      }

    }
  }



}