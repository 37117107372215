.about-top{

  background:url(../assets/img/nosotros/fondo_nosotros_01.jpg);
  background-repeat: no-repeat;
  background-position:center center;
  background-size: cover;
  padding-top:5%;

  h1{
    color: #FFC72A;
    line-height: 45px;
    font-family: Roboto Slab;
    margin-bottom:.5em;

    span.salta{
      display:block;
    }

    @media screen and (max-width: $mobileB) {
      text-align: center;
    }

  }

  .blq_txt{
    margin-bottom: 4em;

    @media screen and (max-width: $mobileB) {
      margin-bottom: 1em;
    }


  }

  p{
    font-size: $txt;
    line-height: 24px;
    margin: 2em 0;

    width: 80%;

    @media screen and (max-width: $mobileB) {
      text-align: center;
      background:rgba(white,.80);
      padding: .25em;
      width: auto;
    }
  }

  @media screen and (max-width: $mobileB) {
    background: url(../assets/img/nosotros/fondo_nosotros_01.jpg);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 150% auto;
    padding-top: 5%;
  }

  
}

.features{

  background-color: $bg_yellow;
  padding: 2em 0;

  .feature-icon-small{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:1em 0;

    img{
      max-width: 50%;
      height: auto;
      text-align: center;
    
      @media screen and (max-width: $mobileB) {
        max-width:20%;
      }
    
    }

    h6 {
      font-weight: bold;
      font-size: $txt*1.10;
      padding-top: 15px;
      text-align: center;
      color:#000;

      span.salta{
        display: block;

        @media screen and (max-width: $desktopS) {
          display: inline;
        }

        @media screen and (max-width: $mobileB) {
          display: block;
        }


      }
    }
  }

}

.gitana{

  background:url(../assets/img/nosotros/fondo_nosotros_02.jpg);
  background-repeat: no-repeat;
  background-position:center top;
  background-size: cover;
  padding-top:8%;

  &__img{
    text-align: right;

    img{
      width: 75%;
      text-align: center;
      height: auto;

      @media screen and (max-width: $desktopS) {
        width: 100%;
        height: auto;
        margin-top: 1em;
      }

      @media screen and (max-width: $tablet) {
        width: 60%;
      }


      @media screen and (max-width: $mobileB) {
        width: 50%;
      }
    }
    
    @media screen and (max-width: $desktopB) {
      text-align: center;
    }

  }

  &__txt{
    padding-top:2%;
  }

  h1{
    color: #FFC72A;
    line-height: 45px;
    font-family: Roboto Slab;
    margin-bottom:.5em;

    span.salta{
      display:block;
    }

    @media screen and (max-width: $desktopS) {
      margin-bottom: .5em;
    }

    @media screen and (max-width: $mobileB) {
      text-align: center;
    }

  }

  p{
    font-size: $txt;
    line-height: 24px;
    margin: 2em 0;

    @media screen and (max-width: $mobileB) {
      text-align: center;
    }

  }
  
}

.timeline{
  margin:3em 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top:5%;

  @media screen and (max-width: $desktopB) {
    margin: 0;
  }

  .gitanaRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-around;

    @media screen and (max-width: $desktopS) {
      flex-direction: column;
    }


  }

  .gitanaTime{
    text-align: center;

    h4{
      font-size:$txt*1.25;
      font-weight: 700;
      color:$txtRc;
      margin-top: 1em;
    }

    img{
      @media screen and (max-width: $desktopB) {
        width: 75%;
        height: auto;
      }
    }

    @media screen and (max-width: $desktopS) {
      margin-bottom:2em;
    }


  }

}

.desde{

  padding:5% 0;
  
  p{
    margin-top: 2em;
    width: 70%;
    font-size: $txt;
    line-height: 24px;

    @media screen and (max-width: $desktopS) {
       width:auto;
       padding:0 2em;
    }

    @media screen and (max-width: $tablet) {
       width:auto;
       padding:0;
    }


    @media screen and (max-width: $mobileB) {
       width:auto;
       padding:0 2em;
    }

  }

}