.producto{

  background:url(../assets/img/productos/bg_productos.jpg);
  background-repeat: no-repeat;
  background-position:center center;
  background-size: cover;

  h4{
    color: #FFC72A;
    font-weight: bold;
    text-transform: uppercase;

    span.salta{
      display:block;
    }

    @media screen and (max-width: $mobileB) {
      text-align: center;
    }

  }

  p{
    font-size: $txt;
    line-height: $lh;
    margin: 2em 0;

    @media screen and (max-width: $mobileB) {
      text-align: center;
      padding: .25em;
      margin: 1em 0 0;
    }
  }

  .tab-pane{
    p{
      font-size: $txt;
      line-height: $lh;
      margin:initial;

      @media screen and (max-width: $mobileB) {
        text-align: center;
        padding: .25em;
        margin: 1em 0 0;
      }
    }
  }
  
  .blq_producto{

    display: flex;
    flex-direction: column;
    margin:5% 1em 5%;
    width: 100%;

      @media screen and (max-width: $tablet) {
        margin: 1em auto;
      }

      @media screen and (max-width: $mobileB) {
        margin: 1em auto 2em;
        padding-bottom: 1em;
        border-bottom: 1px solid $txtRo;
      }

    &__tit{
      display: flex;
      flex-direction: row;

      @media screen and (max-width: $tablet) {
        flex-direction: column;

      }

    }

    &__tit__img{
      @media screen and (max-width: $mobileB) {
        width: 60%;
        margin: 0 auto;
      }

      @media screen and (max-width: $mobile) {
        width: 80%;
      }

      @media screen and (max-width: $mobileS) {
        width: 100%;
      }
    }

    &__tit__txt{
      margin-top:5%;

      p{
        margin: 1em 0;
        line-height: 20px;
      }

      span{
        font-size: $txt*0.85;
      }

      @media screen and (max-width: $mobileB) {
        text-align: center;
      }
    }

    &__tabs{
      margin:5% 1em 1em;

      .nav{
        @media screen and (max-width: $mobileB) {
          justify-content: center;
          align-items: center;
        }
      }

      .nav-pills .nav-link{
        color: #000;
        background-color: #FFF;
      }

      .nav-pills .nav-link.active{
        font-weight: bold;
        border-bottom: 1px solid $yellow;
      }

      .tab-content .cont_sabor{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;

        @media screen and (max-width: $mobileB) {
          justify-content: center;
        }

      }

      .tab-content p.cont_stars{
        border-right: 1px solid #000;
        padding:.25em 1em;

        &:last-of-type {
          border-right: 0;
        }
        
        span.stars{
          margin:0 1em;
          vertical-align: top;

          @media screen and (max-width: $mobileB) {
            display: block;
          }
          @media screen and (max-width: $mobileS) {
            margin:0 .25em;
          }

        }

      }

      .tab-content img.star{
        width: 15px;
        height: 14px;
      }

      .tab-content .tab-pane h3{

        @media screen and (max-width: $mobileB) {
          text-align: center;
        }
      }

      .tab-content .tab-pane#aceite-calidad,
      .tab-content .tab-pane#trufa-calidad{

        @media screen and (max-width: $mobileB) {
          width: 100%;
          margin: 0 auto;
          text-align: center;
        }


      }


    }

  }
&__nav{
  ul{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width:100%;

    @media screen and (max-width: $mobileS) {

      align-items: center;
      flex-direction: column;

    }

    li{
      margin:2em;
      text-align: center;

      @media screen and (max-width: $mobileS) {
        margin:1em;
      }


      img{
        width:100%;
        max-width:120px;
        margin-bottom:1em;
        // opacity: 0.8;
    
        @include hasta(500px){
          width:90px;
        }
      }
    
      a{
        transition:all 0.3s ease-out;
        @include reset-a(#000);
        &:hover{
          color:$txtRo;
         
        }
      }
    }
  }
}
}

.cont_temps{
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cont_temps .temps{
  width: 65%;

  @media screen and (max-width: $desktopS) {

    width: 100%;

  }


}

.cont_temps .temps td{
  border-bottom: 1px solid #E9E9E9;
  padding:0 0 0 10px;
}

.cont_temps .temps td.red{
  text-align: center;
  background-color: #E74B3C;
  color: #FFF;
  padding:0;
}