.recipesFilter{
    padding:2em 0;
    &__filter{
        // display:flex;
        // flex-wrap: wrap;
        ul{
            li{

            }
        }
    }//filter
    &__categories{
        width:25%;
    }//categories
    &__results{
        display:flex;
        width: 100%;
        // align-items: stretch;
        flex-wrap:wrap;
        // width:75%;
        // height: 300px;
        // background:red;
    }//categories
    .recipe__cover{
        width:33%;
        padding:10px;
        @include hasta(767px){
            width: 50%;
        }
        @include mobile{
            width: 100%;
        }

        img{
            // max-width: 100%;
        }
    }
}//recipesFilter
.recipe__cover{
    height: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    @include reset-a(#000);
            display:block;
            // height: 100%;
            &:hover{
                .recipe__cover__link{
                    background:$txtRo;
                    color:$white;

                }
                span{
                    // text-decoration: underline;
                    color:$white;
                }
                .recipe__cover__image{
                   img{
                    transform:scale(1.1);
                   }
                }
            }
    .recipe__cover__image{
        overflow: hidden;
        img{
            transition:all .3s ease-out;
        }
    }
    &__link{
        // display: none;
        // height: 100%;

        background:$yellow;
        text-align:center;
        // height: 20%;
        padding:1em;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        .title{
            // font-size: 18px;
            padding-top:0.5em;
            font-weight: 700;
            margin-bottom:1.5em;
            font-size: 20px;
            line-height: 22px;

            @media screen and (max-width: $desktopS) {
                font-size: 27px;
                line-height: 30px;
            }

            @media screen and (max-width: $tablet) {
                font-size: 20px;
                line-height: 24px;
            }

            @media screen and (max-width: $mobile) {
                font-size: 24px;
                line-height: 28px;
            }

        }
        span{
            margin-bottom:0.5em;
            color:$txtRo;
        }
    }
    img{
        // max-width: 100%;
    }
}//recipe__cover

.recipeDetail{
    &__title{
        padding-top:1em;
        color:$txtRc;
        text-align: center;
        h1{
            padding-top:1em;
            padding-bottom:1em;
           font-family: 'lifemessy';
        }
    }
    &__resum{
        background: $yellow;
        padding:1em 15px;
        li{
            text-align: center;
        }
        .container{
            overflow: hidden;

        }
        ul{
            display: flex;
            justify-content: space-around;
            margin:0;
            @include hasta(767px){
                flex-wrap:wrap;
                li{
                    width:50%;
                    margin-bottom:1em;
                }
            }
            @include mobile{
                li{
                    width:100%;
                }
            }
            p{
                margin:0;
            }
        }//resum
        .label{
            font-weight: 700;
            font-size:20px;
            margin-bottom:0.6em;
        }
    }
    &__info{
        .container{
            display: flex;
            flex-wrap:wrap;
            padding-top:1em;
        }
        .ingredients{
            width:25%;
            padding:15px;
            @include hasta(992px){
                width:40%;
            }
            @include tablet{
                width:100%;
            }
            span{
                color:$txtRc;
            }
            li{
                border-bottom:2px solid #ccc;
                padding:.5em 0;
                font-size: 14px;
                line-height: 16px;
                
                span{
                    display: block;
                    background-color: $bg_rojo2;
                    padding:.5em .25em .25em;
                    text-align: center;    

                    b{
                        color: $white;
                        font-size: $txt;
                    }

                }

                &:last-of-type{
                    border-bottom:none;
                }
            }
        }
        .instructions{
            width:75%;
            padding:15px;
            @include hasta(992px){
                width:60%;
            }
            @include tablet{
                width:100%;
            }
            ul{
                column-count: 2;

                @media screen and (max-width: $mobileB) {
                    column-count: 1;
                }
                
            }
            li{
                margin-bottom:1em;
                
                b{
                    color: $white;
                    display: block;
                    background-color: $bg_rojo2;
                    padding:1em .5em .35em .5em;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;;
                }
            }
            span{
                font-weight: 700;
            }
        }
        .title{
            color:$yellow;
            margin-bottom:1em;
        }
    }
    .return__container{
        a{
            color:$txtRo;
            padding-left:1.5em;
            position: relative;
            font-size:20px;
            span{
                position: absolute;
                left:10px;
                transition:all 0.3s ease-out;
                font-weight: 700;
            }
            &:hover{
                text-decoration:none;
                span{
                    left:0;
                }
            }
        }
        padding-bottom:2em;
    }
}//recipeDetail

.cover{
    @extend .recipe__cover;
}