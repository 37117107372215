
@import 'variables';
@import 'extends';
@import 'mixins';
// @import '../css/libs/bootstrap-all/bootstrap.min.css';
@import '../css/libs/fa/font-awesome.min.css';
@import '../css/modules/common/elegantIcons.css';
@import '../css/modules/common/hmb_style.css';
@import '../css/libs/bootstrap-all/scss/bootstrap-reboot';
@import '../css/libs/bootstrap-all/scss/bootstrap-grid';
@import '../css/libs/bootstrap-all/scss/bootstrap';
// @import '../css/libs/fa/font-awesome.min.css';
@import 'modules/common/common';
@import '../css/libs/slick/slick.css';
@import '../css/libs/slick/slick-theme.css';
@import 'modules/common/header';
@import 'modules/common/footer';
// @import 'modules/common/breadcrumbs';
@import 'modules/common/404';
@import 'modules/home/home';
@import 'modules/recipes/recipes';
@import 'modules/nosotros/nosotros';
@import 'modules/productos/productos';

@import 'modules/contacto/contacto';

// @import 'modules/home/video-module';
// @import 'modules/section/section-banner';
// @import 'modules/section/top-text';
// @import 'modules/section/image-text';
// @import 'modules/section/cases';
// @import 'modules/section/relational';
// @import 'modules/section/bottom-banner';
// @import 'modules/management-screen';
// @import 'modules/section/more-services';
// @import 'modules/blog/grid';
// @import 'modules/blog/detail';
// @import 'modules/contact/form';
// @import 'modules/location/location';
// @import 'modules/static_styles';
 @import 'modules/guide/guide';
 @import 'modules/cooking-tips/tips';
 @import 'modules/beauty/beauty';
 @import 'modules/blog/blog';
