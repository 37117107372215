.blog{
    .header{
        background-image:url("../assets/img/blog/banner.jpg");
    }
}


.blog__grid{
    padding-top:4em;
    padding-bottom:4em;
    .container{
        display:flex;
        flex-wrap: wrap;
    }
    &__article{
        width:33%;
        padding:15px;
        @include tablet{
            width:50%;
        }
        @include mobile{
            width:100%;
        }
        a{
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            @include reset-a (#000);
            &:hover{
                span:before{
                    width:100%;
                }
                .title{
                    color:$txtRc;
                }
            }

            .text{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

        }
        .img{
            margin-bottom:1.5em;
        }
        .title{
            margin-bottom:1.5em;
            text-transform: uppercase;
            font-size: $h4_Grid;
            font-weight: 600;


        }
        .date{
            font-weight: 700;
            text-transform: uppercase;
        }
        .more{
            span{
                position: relative;
                width:auto;
                &:before{
                    transition:width 0.3s ease-out;
                    content:"";
                    width:0%;
                    position: absolute;
                    border-bottom:2px solid $yellow;
                    bottom:-3px;
                }
            }
        }
    }//__article
}


/*
 /$$$$$$$  /$$$$$$$$ /$$$$$$$$ /$$$$$$  /$$       /$$       /$$$$$$$$
| $$__  $$| $$_____/|__  $$__//$$__  $$| $$      | $$      | $$_____/
| $$  \ $$| $$         | $$  | $$  \ $$| $$      | $$      | $$      
| $$  | $$| $$$$$      | $$  | $$$$$$$$| $$      | $$      | $$$$$   
| $$  | $$| $$__/      | $$  | $$__  $$| $$      | $$      | $$__/   
| $$  | $$| $$         | $$  | $$  | $$| $$      | $$      | $$      
| $$$$$$$/| $$$$$$$$   | $$  | $$  | $$| $$$$$$$$| $$$$$$$$| $$$$$$$$
|_______/ |________/   |__/  |__/  |__/|________/|________/|________/
                                                                     
                                                                     
                                                                     
*/
.blog-detalle{
    .header{
        background-image:url("../assets/img/blog/banner.jpg");
    }
}
.blog__detail{

    padding-top:4em;
    @extend .recipeDetail;
    .return__container{
        
    }
    .title{
 
        padding-bottom:1em;
        h1{
            color:$yellow;
            font-weight: 700;
            font-size: 2em;
            line-height: normal;
        }
        
    }
    .content{

        margin-bottom: 2em;

        img{
            margin-top:2em;
            margin-bottom:2em;
            max-width:100%;
        }

        h2{
            font-size: 18px;
            font-weight: 700;
            line-height: normal;
            color: $txtRo;
        }

        p{
            font-size: 15px;
            line-height: 22px;
        }

    }
}