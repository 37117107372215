@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
// @import url(../../src/css/libs/fa/font-awesome.min.css);
// @import url(../../src/css/modules/common/elegantIcons.css);
// @import url(../../src/css/modules/common/hmb_style.css);

@font-face {
    font-family: 'lifemessy';
    src: url("fonts/lifemessy-webfont.eot");
    src: url("fonts/lifemessy-webfont.eot") format("embedded-opentype"), 
         url("fonts/lifemessy-webfont.woff") format("woff"), 
         url("fonts/lifemessy-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}



body{
  font-family: Raleway;
  color: $txtB;
  line-height: $lh;
}

img{
  width: 100%;
  height: auto;
}

.clearfix{
  clear:both;
}

.paginator{
  margin-bottom:2em;
  ul{
   li{
     margin: 0 5px;
   }
  }
}
.lead{

  b{
    font-weight: 700;   
  }
  a{
    @include reset-a ($bg_rojoHov);
    &:hover{
      text-decoration: underline;
    }
  }

} 

.cta{
  a{
    transition: all 0.3s ease-out;
    @include reset-a(#000);
    background:$yellow;
    padding:0.5em 1em;
    text-transform: uppercase;
   &:hover{
     background:$txtRo;
     color:#fff;
   } 
  }
}


//   ___  ___  _    ___ _____ ___ ___   _      ___ ___   ___  _  _____ ___ ___ 
//  | _ \/ _ \| |  |_ _|_   _|_ _/ __| /_\    / __/ _ \ / _ \| |/ /_ _| __/ __|
//  |  _/ (_) | |__ | |  | |  | | (__ / _ \  | (_| (_) | (_) | ' < | || _|\__ \
//  |_|  \___/|____|___| |_| |___\___/_/ \_\  \___\___/ \___/|_|\_\___|___|___/
//                                                                             

.blq_cookies{
  background:url(../assets/img/common/cookies-bg.jpg);
  background-repeat: no-repeat;
  background-position:center top;
  background-size: cover;
  padding-top:2%;
}

.blq_cookies .row{
  margin:2em 0;
  border-bottom:2px solid $yellow;
}

.txt-cookies{

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media screen and (max-width: $desktopB) {
    flex-direction: column;
  }

  @media screen and (max-width: $mobileB) {
    text-align: center;
  }

  &:last-of-type{
    margin-bottom: 2em;
  }

  &__izq{
    width: 50%;

    @media screen and (max-width: $desktopB) {
      width: auto;
    }


    h3{
      color: $yellow;
      font-size: 2.15em;
    }
  }

  &__dch{
    width: 50%;

    @media screen and (max-width: $desktopB) {
      width: auto;
    }

    p{
      font-size: $txt*.9;
    }

    a{
      color: inherit;
      text-decoration: none;

      &:hover{
        text-decoration: underline;
      }
    }

  }

  span.salta{
    display: block;
  }

  .sub-tit{
    color: $yellow;
    margin-top: 2em;
  }

  ul{
    list-style-type: none;
    padding: 0;

    li{
      margin-bottom: 1em;
      line-height: 18px;
      font-size: 14px;
    }

    span.bull{
      font-size:$txt*2.5;
      vertical-align: middle;
      color: $yellow;
    }

  }

}

.table-cookies{

  width: 100%;

  h3{
    color: $yellow;
    font-size: 2.15em;
    line-height: 1.25em;
    font-weight: bold;

    span.salta{
      display: block;
    }

  }

  table{
    margin: 2em 0;
    font-size: $txt*.80;

    @media screen and (max-width: $tablet) {
      display: none;
    }

    tbody td{
      vertical-align: middle;

      a{
        color: #000;
      }
    }

  }

  .cookies_mb{
    display: none;


    @media screen and (max-width: $tablet) {
      display: flex;
      flex-direction:column;
      
    }

    span{
      // display: block;
      font-weight: bold;
      color:$txtRo;
      font-size: $txt*.85;
    }

    .blq_cookie{
      margin:1em 0;
      border-bottom:2px solid $yellow;

      a{
        color: #000;
      }
    }


  }

}

.bajo_cookies{
  column-count: 2;
  margin: 2em 0;

  @media screen and (max-width: $desktopB) {
    column-count: 1;
  }

  @media screen and (max-width: $mobileB) {
    text-align: center;
  }

}

h5{
  color: $yellow;
  font-weight: bold;
}

.tipo-cookies{

  margin-top:1em;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }

  a{
    color: #000;
    
  }
  .columna{
    padding-right: 1em;
  }

  .sub-tit{
    color: $yellow;
  }

  ul{
    list-style-type: none;
    padding: 0;

    li{
      margin-bottom: 1em;
      line-height: 18px;
      font-size: 14px;
    }

    span.bull{
      font-size:$txt*2.5;
      vertical-align: middle;
      color: $yellow;
    }

  }
}

//     ___   _____ ___  ___    _    ___ ___   _   _    
//    /_\ \ / /_ _/ __|/ _ \  | |  | __/ __| /_\ | |   
//   / _ \ V / | |\__ \ (_) | | |__| _| (_ |/ _ \| |__ 
//  /_/ \_\_/ |___|___/\___/  |____|___\___/_/ \_\____|
//                                                     

.blq_legal{
  background:url(../assets/img/common/legal-bg.jpg);
  background-repeat: no-repeat;
  background-position:center top;
  background-size: cover;
  padding-top:2%;
}


.blq_legal .row{
  margin:2em 0 1em;
  border-bottom:2px solid $yellow;

  .sub-tit{
    color: $yellow;
  }

  &:last-of-type{
    border-bottom:none;
    margin:2em 0 0;
  }
}


.txt-legal{

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1em;

  @media screen and (max-width: $desktopB) {
    flex-direction: column;
  }

  @media screen and (max-width: $mobileB) {
    text-align: center;
  }


  &__izq{
    width: 50%;

    @media screen and (max-width: $desktopB) {
      width: auto;
    }

    h3{
      color: $yellow;
      font-size: 2.15em;
    }
  }

  &__dch{
    width: 50%;

    @media screen and (max-width: $desktopB) {
      width: auto;
    }

    p{
      margin-bottom:.25em;
      font-size: $txt*.85;
    }

    a{
      color: inherit;
      text-decoration: none;

      &:hover{
        text-decoration: underline;
      }
    }

  }

}

.entre-tablas{
  margin:1em 0 0;
}

.table-legal{

  width: 100%;

  h3{
    color: $yellow;
    font-size: 2.15em;
    line-height: 1.25em;
    font-weight: bold;

    span.salta{
      display: block;
    }

  }


  table{
    margin: 1em 0;
    font-size: $txt*.80;

    @media screen and (max-width: $tablet) {
      display: none;
    }

    tbody td{
      vertical-align: middle;

      a{
        color: #000;
      }
    }

  }

  .legal01_dk table tbody td{
    width: 33%;
  }

  .legal02_dk table tbody td{
    width: 50%;
  }

  .legal01_mb{
    display: none;

    @media screen and (max-width: $tablet) {
      display: flex;
      flex-direction:column;
      
    }

    span{
      // display: block;
      font-weight: bold;
      color:$txtRo;
      font-size: $txt*.85;
    }

    .row_legal{
      border-bottom:1px dotted $txtRo;
      padding: 1em;

      &:last-of-type{
        border-bottom: 0;
      }

      p{
        margin:.75em 0;
      }

      a{
        color: #000;
      }
    }

  }

  .legal02_mb{
    display: none;

    @media screen and (max-width: $tablet) {
      display: flex;
      flex-direction:column;
      
    }

    span{
      // display: block;
      font-weight: bold;
      color:$txtRo;
      font-size: $txt*.85;
    }

    .row_legal{
      border-bottom:1px dotted $txtRo;
      padding: 1em;

      &:last-of-type{
        border-bottom: 0;
      }

      p{
        margin:.75em 0;
      }

      a{
        color: #000;
      }
    }

  }

}

.bajo-legal{

  a{
    color: #000;
  }
}

.tipo-legal{

  margin-top:1em;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: $tablet) {

    flex-direction: column;

  }

  .column{
    padding-right: 1em;
    width: 50%;

    @media screen and (max-width: $tablet) {
      width: auto;
    }

  }

  .sub-tit{
    color: $yellow;
  }

  ul{
    list-style-type: none;
    padding: 0;

    li{
      margin-bottom: 1em;
      line-height: 18px;
      font-size: 14px;
    }

    span.bull{
      font-size:$txt*2.5;
      vertical-align: middle;
      color: $yellow;
    }

  }
}
