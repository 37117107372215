
.guide header,
.guide-detail header{
    background:url(../assets/img/guide/banner.jpg)no-repeat center center /cover
}
.guiaGrid{
    background:url(../assets/img/guide/home-bg.jpg) no-repeat top left /cover;
    padding:5em 0;
    &__grid{
        display: flex;
        flex-wrap: wrap;
    }
    .cover{
        width:50%;
        padding:15px;
        @include mobile{
            width:100%;
        }
    }

    .cta{
        
        margin:5% auto 0;

        h3{
            margin-bottom: 1em;
        }

        a {
            -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
            text-decoration: none;
            color: #000;
            background: #ffc62a;
            padding: 0.75em 2em;
            font-weight: bold;
            text-transform: uppercase;

            &:hover{
                background: $bg_rojoHov;
                color:$white;
            }
        }
    }

}

.guide__detail{
    @extend .recipeDetail;
    padding:5em 0;
    background:url(../assets/img/guide/fondo_interior_guia.png) no-repeat top left /cover;

    &__section{
        display: flex;
        border-bottom:2px solid $yellow;
        padding:3em 0 ;
        @include tablet{
            flex-wrap: wrap;
            background:rgba(white,0.5);
            padding:3em 1em;
        }
        &:last-of-type(){
            border:none;
        }
    }
    .title{
        width:40%;
        @include tablet{
            width:100%;
        }
        p{
            font-size:40px;
            line-height: 1.2em;
            color:$yellow;
            font-weight:700;
            @include hasta(600px){
                font-size: 32px;
            }
        }
    }
    .text{
        width:60%;
        padding-left:2em;
        @include tablet{
            padding-left:0;
            width:100%;
        }
        p{
            font-size: 18px;
            line-height: 1.2em;
            @include hasta(600px){
                font-size: 16px;
            }    
        }
    }
    .cta{
        @extend .producto;

    }
}