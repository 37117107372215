/*
_____  _    _____ 
 | |  \ \_/  | |  
 |_|  /_/ \  |_|  

 */
// $bigmsn:60px;
// $txt1:28px;
// $txt2:24px;
// $txt3:20px;
// $txt4:18px;
// $txt5:14px;
$txt:16px !default;
$lh:19px !default;

$h1:30px;
$h2:28px;

$h4_Grid:20px;

$section-title:36px;
$banners:64px;


/*
Tipos
*/
// $normal:'UniversLTStd';
// $bold:'UniversLTStd-Bold';


/*
 __    ___   _     ___   ___   ____  __  
/ /`  / / \ | |   / / \ | |_) | |_  ( (` 
\_\_, \_\_/ |_|__ \_\_/ |_| \ |_|__ _)_) 

*/
$bg_rojo:#E84C3D;
$bg_rojo2:#BD2E1E;
$bg_rojoHov:#9D2C22;
$bg_yellow:#FEC629;

$txtW:#FFFFFF;
$txtB:#000000;
$txtGr:#666666;
$txtRo:#9D2C22;
$txtRc:#E84C3D;
$yellow:#ffc62a;

// $valor:#003b71;

// $body-color:#fff;
// $bp-green:#006737;
// $alert:#d41b1b;
// $hover:#a7cc2a;
// $bp-soft:#009900;




/*
 ___   ____  __   ___  
| |_) | |_  ( (` | |_) 
|_| \ |_|__ _)_) |_|   
*/
$desktop:1920px;
$desktopB:1200px;
$desktopS:992px;
$tablet: 768px;
$hmb:660px;
$mobileB:580px;
$mobile:425px;
$mobileS:330px;