@mixin reset-a($color-a){
		text-decoration:none;
		color:$color-a;
		&:hover{
			text-decoration: none;
			outline:none;
		}
		&:focus,&:visited{
			text-decoration:none;
			color:$color-a;
			outline:none;
		}
	}

@mixin inputPlace($color-place, $fondo){
		color:$color-place;

		&:focus{
			border:0;
			outline: 0;
    	background-color:$fondo;
		}
	
		&::-moz-placeholder{
			color:$color-place;
			opacity:1;
		}
		
		&:-ms-input-placeholder{
			color:$color-place;
		}
		
		&::-webkit-input-placeholder{
			color:$color-place;
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus{
			border: none;
			// border-bottom:1px solid $color-place;
			-webkit-text-fill-color:$color-place;
			-webkit-box-shadow: 0 0 0px 1000px $fondo inset;
			transition: background-color 5000s ease-in-out 0s;
		}

}


@mixin kristiTit{ //Tipografía de los banner principales
	font-family: "Kristi", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 76px;
	line-height: 78px;
}

// @mixin shadow{
// 	box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.35);
// }

@mixin hasta($hasta) {
	@media (max-width: #{$hasta}) {
		@content;
	}
	}
	@mixin desde($desde) {
		@media (min-width: #{$desde}) {
			@content;
		}
		}
	@mixin desktopB {
		@media (min-width: #{$desktop + 1}) {
		  @content;
		}
	  }
	@mixin desktop {
		@media (max-width: #{$desktop}) {
		  @content;
		}
	  }
	  @mixin desktopS {
		@media (max-width: #{$desktopS}) {
		  @content;
		}
	  }
	@mixin tablet {
		@media (max-width: #{$tablet}) {
		  @content;
		}
	  }
	@mixin mobileB {
		@media (max-width: #{$mobileB}) {
		  @content;
		}
	  }
	@mixin mobile {
		@media (max-width: #{$mobile}) {
		  @content;
		}
	  }
	@mixin mobileS {
		@media (max-width: #{$mobileS}) {
		  @content;
		}
	  }

//@include desktop {}





// @mixin placeH($col){
// 	  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
// 		color: $col;
// 	  }
// 	  &::-moz-placeholder { /* Firefox 19+ */
// 		color: $col;
// 	  }
// 	  &:-ms-input-placeholder { /* IE 10+ */
// 		color: $col;
// 	  }
// 	  &:-moz-placeholder { /* Firefox 18- */
// 		color: $col;
// 	  }
// }
//@include placeholder (#fff);






/////////////////////////////////////////////////////////////////////////////////////
	// @mixin mediaQ($media) {
	// 	@if $media == big-desktop {
	// 	  @media only screen and (min-width: $desktop) { @content; }
	// 	}
	// 	@else if $media == desktop {
	// 	  @media only screen and (max-width: $desktop - 1) { @content; }
	// 	}
	// 	@else if $media == tablet {
	// 	  @media only screen and (max-width: $tablet) { @content; }
	// 	}
	// 	@else if $media == mobile {
	// 		@media only screen and (max-width: $tablet - 1) { @content; }
	// 	  }
	// 	  @else if $media == mobileMini {
	// 		@media only screen and (max-width: $mobile-mini) { @content; }
	// 	  }

	// }

	// @include mediaQ(handhelds) { width: 100% ;}

////////////////////////////////////////////////////////////////////////////////////////////









// @mixin ma-shadow (){
// 	-webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
//     -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
//     box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
// }

// @mixin var-td($fondo){
// 				width:17%;
//                 background: $fondo;
//                 padding:0.7em;
// 				border-right: 6px solid #fff;
// 				&:nth-child(5){
//                     border:none;
//                 }
// }

// @mixin box-sombras($color){
// 					-webkit-box-shadow: 8px 8px 0px 0px $color;
// -moz-box-shadow: 8px 8px 0px 0px $color;
// box-shadow: 8px 8px 0px 0px $color;
// }
// @mixin reset-a($color-a){
// 	text-decoration:none;
// 	color:$color-a;
// 	&:hover,&:focus,&:visited{
// 		text-decoration:none;
// 		color:$color-a;
// 		outline:none;
// 	}
// }

// @mixin img-sombra($color){
// 	-webkit-box-shadow: -8px 8px 0px 0px$color;
// -moz-box-shadow: -8px 8px 0px 0px $color;
// box-shadow: -8px 8px 0px 0px $color;
// }

// @mixin transition{
// 	transition:all 0.3s ease-out;
// }
// @mixin transition-t($time){
// 	transition:all $time ease-out;
// }
// .cajamix{
// 	@include colores(#000,green);
// 	color:#fff;
// }
