.header{
  width:100%;
  // max-width: 1920px;
  background: {
    size:100%;
    repeat: no-repeat;
    position: top center;
    attachment: fixed;
  }
  @include hasta(1279.5px){
    background: {
      size:cover;
      repeat: no-repeat;
      position: center center;
      attachment:initial;
    }

  }
  margin:0 auto;
  height: 500px;
  @include desde(1660px){
    height: 600px;
  }
  @include desde(2000px){
    height: 700px;
  }
  @include desde(2500px){
    height: 800px;
  }
  

    @media screen and (max-width: $mobileB) {
      height:$mobile;
    }

    @media screen and (max-width: $mobile) {
      height:$mobileS;
    }

    @media screen and (max-width: $mobileS) {
      height:$mobileS/1.25;
    }



  .container{
    display:flex;
    align-items: unset;
    flex-direction: column;

    @media screen and (max-width: $tablet) {
      max-width: 100%;
    }

    @media screen and (max-width: $desktopS) {
      flex-direction: column;
      text-align: center;
    }


  }

  .menu.dk{
    display:flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    
    @media screen and (max-width: $hmb) {
      display: none;
    }

    @media screen and (max-width: $desktopS) {
      flex-direction: column;
    }

    .header__nav{

      @media screen and (max-width: $desktopB) {
        padding: 0;
      }

    }


  }

  .menu.mb{
    display: none;

    @media screen and (max-width: $hmb) {
      display: flex;
      flex-direction: column;
    }

    .o-grid__item{
      position:absolute;
      top: 0;
      left:0;
      transform: scale(0.65);

      @media screen and (max-width: $mobile) {
        transform: scale(0.55);
        top: -1em;
        left: -1em;
      }

      @media screen and (max-width: $mobileS) {
        transform: scale(0.50);
        top: -1.30em;
        left: -1.30em;
      }


    }
    
  }
  
  .dropdown-menu.show{
    background: $yellow;
  }

  .dropdown-item.active{
    background:$yellow;
    color:#666666;

    &:hover{
      background: $white;
    }
  }
  .dropdown-item:active{
    background: initial;
  }

  &__logo{

    @media screen and (max-width: $hmb) {

      display: flex;
      justify-content: center;
      align-items: center;


    }
    
  }

  &__logo img{
    margin: 0 ;
    text-align: center;

    @media screen and (max-width: $desktop) {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: $desktopS) {
      width: 45%;
      height: auto;
      margin:.25em 0;
    }

    @media screen and (max-width: $hmb) {
      width: 50%;
      margin: 2.5% 0;
    }

    @media screen and (max-width: $mobileS) {
      width: 65%;
      margin: 3% 0;
    }
    

  }

  &__nav{

    .nav {justify-content: center;}
    .nav-item{border-right:1px solid #FFC72C;}
    .nav-item:last-child(){border:0;}

    .nav-link{
      padding: .20rem 0.5rem;

      @media screen and (max-width: $desktopB) {
        padding: .20rem .5rem;
      }

    }

    @media screen and (max-width: $desktopS) {
      padding:.5em 0;
      align-items: center;
      justify-content: center;
    }

  } //__nav

  & .header__nav__mobile{
    display:none;

    & nav.nav.mb{
        display:none;
        text-align: center;
        transition:all 0.5s linear;
        padding-left:0; 

        &.active{
          display: block;
          transition:all 0.5s linear;
         
          & >.nav-item{
            pointer-events: inherit;
          }
        }

    }

    @media screen and (max-width: $hmb) {
      display: block;
    }

  }


  &__contNav{
    background: {
      color:$bg_rojo;
    } 
    opacity:.90;
    position: relative;
    z-index: 50;

    .productos{
      // background:none;
    }
    
    & a.nav-link{
      color:$txtW;
      font-size: 14px;
        
      &:hover,
      &.active{
        color:$yellow;
      }
      &.disabled{
        opacity:0.6;
        pointer-events:none;
      }

      &.active, &:focus{
        // border-bottom:1px solid #FFC72C;
        background-color:transparent;
      }
    }

    @media screen and (max-width: $hmb) {
      // min-height: 100px;
    }

    @media screen and (max-width: $desktopS) {
      padding: .25em 0 0;
    }

  } //__contNav

  .linksSocial{
    display: flex;
    justify-content: flex-end;
    margin:.25em 0 0;

    & i.fa{
      color:$txtW;
      font-size:$txt*1.25;
      margin:0 .10em;
    }

    @media screen and (max-width: $hmb) {
      position: absolute;
      right:.5em;
      margin:0;

    }

  } //linksSocial



}//header

// IMAGENES HEADERS
.home .header,
.cuatro-cero-cuatro .header{
  height: auto;
}
.nosotros .header{
  background-image: url('../assets/img/nosotros/header_nosotros.jpg');
}
.recipes .header{
  background-image: url('../assets/img/recipes/header_recetas.jpg');
}
.productos .header{
  background-image: url('../assets/img/productos/header_productos.jpg');
}
.contacto .header{
  background-image: url('../assets/img/contacto/banner_contacto.jpg');
}
.aviso-cookies .header{
  background-image: url('../assets/img/common/header-politica-privacidad.jpg');
}
.aviso-legal .header{
  background-image: url('../assets/img/common/header-aviso-legal.jpg');
}
.polanco .header{
  background-image: url('../assets/img/polanco/banner_polanco.jpg');
}

.polanco-legal .header{
  background-image: url('../assets/img/polanco/banner_polanco.jpg');
}
