.cooking-tips{
    header{
        background-image:url("../assets/img/cooking-tips/banner.jpg");
    }
}
.tips{
    .container{
        padding:3em 0;
        flex-wrap: wrap;
    }
    .tip{
        @include reset-a(#666);
        margin-bottom:1.5em;
        &__img{
            overflow: hidden;
            img{
                transform: scale(1.2) rotate(5deg);
                max-width:100%;
                transition:transform 0.3s ease-out;
            }
        }
        &__title{
            padding-top:1em;
            h2{
                font-size:26px;
                @include tablet{
                    font-size:24px;
                }
                @include mobile{
                    font-size:20px;
                }
            }
        }
        &:hover{
            .tip__img{
                img{
                    transform:scale(1)
                }
            }
            .tip__title{
                padding-top:1em;
                h2{
                    font-size:26px;
                    color:$txtRo;
                }
            }
        }
    }
    
}
#video-modal{
    .modal-content{
        border:none;
        outline: none;
        // display: block;
        background:transparent;
        // position: relative;
        // width: 100%;
        // // height: 0;
        // padding-bottom: 56.25%;
        &:focus{
            outline: none;
        }
    }
    .modal-body{
        padding:0;
        // position: relative;
    }
    video{
        max-width:100%;
        outline: none;
    }
    iframe{
        margin: 0 auto;
        display: block;
    
        width: 800px;
        height: 450px;
    
        @include hasta(720px){
            width:100%;
            height: auto;
        }
    }
    .close-icon{
        pointer-events: none;
        font-size: 30px;
        color:#fff;
        position:absolute;
        right: 0;
        font-weight: bold;
        top:-30px;
    }
}